const theme = {
  colors: {
    primary: '#f3b909',
    secondary: '#0f0a08',  // Adjusted this to be the new header color
    accent: '#715800',
    background: '#f8dfa3',
    header: '#0f0a08',  // Added this for the header specifically
    white: '#ffffff', // White color for icons and text
  },
  breakpoints: {
    mobile: '600px',
  },
};

export default theme;
