import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import GlobalStyle from './globalStyles';
import theme from './theme';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import BecomeASponsor from './pages/BecomeASponsor';
import ContactUs from './pages/ContactUs';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.div`
  flex: 1;
  overflow-x: hidden; /* Prevent horizontal scroll */
  padding-top:30px;
`;

const ContentWrapper = styled.div`
  padding: 50px; /* Adjust padding as needed */
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 50px; /* Gap between header and main content */
  // margin-top: 10px; /* Margin to create a gap between header and content */
  
`;

const App = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Router>
      <AppContainer>
        <Header />
        <Content>
          <ContentWrapper className="content-wrapper">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/become-a-sponsor" element={<BecomeASponsor />} />
              <Route path="/contact-us" element={<ContactUs />} />
            </Routes>
          </ContentWrapper>
        </Content>
        <Footer />
      </AppContainer>
    </Router>
  </ThemeProvider>
);

export default App;
