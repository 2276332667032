import React from 'react';
import styled from 'styled-components';
import { FaTwitter, FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';

const FooterContainer = styled.footer`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.secondary};
  padding: 20px;
  text-align: center;
  width: 100%;
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const SocialIconLink = styled.a`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 1.5em;
  margin: 0 10px;
  transition: color 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const Footer = () => {
    // Get the current year dynamically
    const currentYear = new Date().getFullYear();
    return(
  
      <FooterContainer>
        <div>
          <p> © {currentYear} Africa Cybersecurity Awards. All rights reserved. </p>
          <p>........................</p>
        </div>
        <SocialIcons>
          <SocialIconLink href="https://x.com/AfriCyberAwards" target="_blank" rel="noopener noreferrer">
            <FaTwitter />
          </SocialIconLink>
          <SocialIconLink href="#">
            <FaFacebook />
          </SocialIconLink>
          <SocialIconLink href="https://www.instagram.com/africacybersecurityawards?igsh=YnZxbW82ZXlnajE3" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </SocialIconLink>
          <SocialIconLink href="https://www.linkedin.com/company/africa-cybersecurity-awards-aca/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
            <FaLinkedin />
          </SocialIconLink>
        </SocialIcons>
      </FooterContainer>
  );
};

export default Footer;
