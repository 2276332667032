import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  /* @import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400..900&display=swap'); */
   @font-face {
    font-family: 'georgia';
    src: url('../assets/fonts/georgia.ttf') format('ttf'),
         url('../assets/fonts/georgiab.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'georgia';
    src: url('../assets/fonts/georgia.ttf') format('ttf'),
         url('../assets/fonts/georgiab.ttf') format('ttf');    
         font-weight: 700;
    font-style: normal;
  }

  body {
    // font-family: 'georgia', sans-serif;
    margin: 0;
    padding: 0;
    background-color: ${({ theme }) => theme.colors.header};
    color: ${({ theme }) => theme.colors.secondary};
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-x: hidden; /* Prevent horizontal scroll */
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
  }

  p {
    font-weight: 400;
  }

  a {
    font-weight: 500;
    text-decoration: none;
  }

  button {
    font-weight: 500;
    cursor: pointer;
  }

  #root {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-x: hidden; /* Prevent horizontal scroll */
  }

  .content-wrapper {
    padding: 20px; /* Adjust padding as needed */
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px; /* Gap between header and main content */
    margin-top: 30px; /* Margin to create a gap between header and content */
    overflow-x: hidden; /* Prevent horizontal scroll */
  }

  /* Ensure the header is fixed at the top */
  header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.header};
    padding: 10px;
    text-align: center;
    z-index: 1000;
  }

  /* Adjust content area for mobile view */
  @media (max-width: 768px) {
    .content-wrapper {
      padding: 10px;
    }
  }
`;

export default GlobalStyle;
