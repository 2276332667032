import React, { useRef } from 'react';
import styled from 'styled-components';

const ContactContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary};
  margin-top: 20px;
`;

const ContactTitle = styled.h2`
  font-size: 2em;
  margin-bottom: 20px;
  text-align: center;
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const Label = styled.label`
  margin-bottom: 5px;
  font-weight: bold;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
`;

const TextArea = styled.textarea`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
`;

const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.secondary};
  padding: 10px 20px;
  font-size: 1.2em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-self: flex-start;
`;

// const SubscribeContainer = styled.div`
//   display: flex;
//   align-items: center;
//   margin-top: 20px;
//   width: 100%;
//   max-width: 600px;
// `;

// const SubscribeInput = styled.input`
//   flex: 1;
//   padding: 10px;
//   border: 1px solid #ccc;
//   border-top-left-radius: 5px;
//   border-bottom-left-radius: 5px;
//   font-size: 1em;
// `;

// const SubscribeButton = styled.button`
//   background-color: ${({ theme }) => theme.colors.primary};
//   color: ${({ theme }) => theme.colors.secondary};
//   padding: 10px 20px;
//   font-size: 1em;
//   border: none;
//   border-top-right-radius: 5px;
//   border-bottom-right-radius: 5px;
//   cursor: pointer;
// `;

const ContactUs = () => {
  const formRef = useRef(null);

  const handleSubmit = (event) => {
    event.preventDefault();

    const name = event.target.name.value;
    const email = event.target.email.value;
    const subject = event.target.subject.value;
    const message = event.target.message.value;

    const mailtoLink = `mailto:info@africacybersecurityawards.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
      `Name: ${name}\nEmail: ${email}\n\nMessage:\n${message}`
    )}`;

    window.location.href = mailtoLink;

    // Reset the form
    if (formRef.current) {
      formRef.current.reset();
    }
  };

  return (
    <ContactContainer>
      <ContactTitle>Contact Us</ContactTitle>
      <p>If you have any questions, please email us or fill out the contact form below</p>
      <ContactForm ref={formRef} onSubmit={handleSubmit}>
        <FormField>
          <Label htmlFor="name">Name</Label>
          <Input type="text" id="name" name="name" required />
        </FormField>
        <FormField>
          <Label htmlFor="email">Email</Label>
          <Input type="email" id="email" name="email" />
        </FormField>
        <FormField>
          <Label htmlFor="subject">Subject</Label>
          <Input type="text" id="subject" name="subject" />
        </FormField>
        <FormField>
          <Label htmlFor="message">Message</Label>
          <TextArea id="message" name="message" rows="5" />
        </FormField>
        <Button type="submit">Send Message</Button>
      </ContactForm>
      {/* <SubscribeContainer>
        <SubscribeInput type="email" placeholder="Subscribe to our newsletter" />
        <SubscribeButton>Subscribe</SubscribeButton>
      </SubscribeContainer> */}
    </ContactContainer>
  );
};

export default ContactUs;
