import React, { useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa'; // Import icons for hamburger and close

const HeaderContainer = styled.header`
  background-color: ${({ theme }) => theme.colors.header};
  padding: 10px 20px; /* Added horizontal padding */
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 6px rgba(243, 185, 9, 0.5); /* Gold drop shadow */
  box-sizing: border-box; /* Ensure padding does not affect the width */
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1; /* Ensure it takes up available space */
`;

const LogoImage = styled.img`
  height: 60px;
  margin-right: 10px;
`;

const LogoText = styled.div`
  font-size: 1.5em;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  flex: 2; /* Ensure it takes up available space */
  justify-content: flex-end; /* Align menu items to the right */
  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    top: 83px; /* Adjust as needed */
    right: 3px; /* Adjust for alignment */
    background-color: ${({ theme }) => theme.colors.header};
    width: calc(100% - 40px); /* Full width minus padding */
    max-width: 300px; /* Limit max width */
    padding: 20px; /* Spacing inside the menu */
    box-shadow: 0 2px 6px rgba(243, 185, 9, 0.5); /* Gold drop shadow */
    z-index: 999; /* Ensure it’s above other content */
    border-radius: 5px; /* Rounded corners */
  }

  @media (min-width: 769px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end; /* Align items to the right on larger screens */
    gap: 20px; /* Spacing between the menu items */
    padding: 0; /* Remove padding */
  }

  a {
    margin: 0 15px; /* Horizontal spacing for larger screens */
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease-in-out;
    font-size: 1.2em; /* Adjust font size for readability */
    padding: 10px; /* creates space of menu items */

    &:hover {
      color: ${({ theme }) => theme.colors.accent};
    }

    &.active {
      color: ${({ theme }) => theme.colors.accent};
    }
  }
`;

const IconContainer = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    padding: 10px; /* Padding around the icon */
    cursor: pointer;
    position: relative;
    margin: 0 15px; /* Margin for spacing from viewport edges */
  }
`;

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <HeaderContainer>
      <LogoContainer>
        <LogoImage src="/logo.png" alt="Cybersecurity Awards Logo" />
        <LogoText>Africa Cybersecurity Awards</LogoText>
      </LogoContainer>
      <IconContainer onClick={toggleNav}>
        {isNavOpen ? <FaTimes size={24} color="#fff" /> : <FaBars size={24} color="#fff" />}
      </IconContainer>
      <Nav isOpen={isNavOpen}>
        <NavLink exact to="/" activeClassName="active" onClick={toggleNav}>
          Home
        </NavLink>
        <NavLink to="/become-a-sponsor" activeClassName="active" onClick={toggleNav}>
          Become a Sponsor
        </NavLink>
        <NavLink to="/contact-us" activeClassName="active" onClick={toggleNav}>
          Contact Us
        </NavLink>
      </Nav>
    </HeaderContainer>
  );
};

export default Header;
