import React from 'react';
import styled from 'styled-components';
import sponsorLogo1 from '../assets/donate_icon2.png'; // Ensure you have the correct path to your images

const SponsorContainer = styled.div`
  padding: 20px;
  text-align: center;
  margin-top: 20px;
  text-align: justify;
`;

const SponsorTitle = styled.h2`
  font-size: 2em;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.primary};
`;

const LogoBanner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
`;

const SponsorLogo = styled.img`
  max-width: 100%;
  height: auto;
  max-width: 400px;
`;

const SponsorText = styled.p`
  font-size: 1.2em;
  color: ${({ theme }) => theme.colors.primary};
  text-align: left;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: left;
`;

const SponsorLink = styled.a`
  color: #FFFFFF; /* Set the text color to white */
  text-decoration: none;
  font-weight: bold;

  &:hover {
    color: ${({ theme }) => theme.colors.accent};
  }
`;

const BecomeASponsor = () => (
  <SponsorContainer>
    <SponsorTitle>Sponsorship, Support and Donation</SponsorTitle>
    <LogoBanner>
      <SponsorLogo src={sponsorLogo1} alt="Sponsor Logo 1" />
    </LogoBanner>
    <SponsorText>
      We offer a wide range of opportunities for organizations and individuals to support and engage with the Africa Cybersecurity Awards. Beyond traditional sponsorship tiers, we welcome various forms of contributions, including in-kind support, media partnerships, speaker sponsorships, exhibition support, and donations.
    </SponsorText>
    <SponsorText>
      In-kind sponsors can provide goods or services that enhance our event, while media sponsors gain visibility through extensive promotional activities. Speaker sponsors have the opportunity to support specific sessions or speakers, and exhibition sponsors can showcase their offerings to a targeted audience.
    </SponsorText>
    <SponsorText>
      Donors, whether contributing financially or with products, receive acknowledgment across multiple channels. Every form of support is crucial to the success of our event and offers unique benefits to our partners. We invite you to join us in making a meaningful impact on cybersecurity in Africa while gaining valuable exposure for your brand.
    </SponsorText>
    <SponsorText>
      If you’re interested or would like to learn more about sponsorship, support, and donation opportunities, please contact us at{' '}
      <SponsorLink href="mailto:info@africacybersecurityawards.com">info@africacybersecurityawards.com</SponsorLink>.
    </SponsorText>
  </SponsorContainer>
);

export default BecomeASponsor;
